<template lang="pug">
div
	v-col.px-0.py-1(v-if='this.information && this.items.length !== 0')
		.memberInfoCard
			.pl-2.pb-7.memberInfoCardText(v-for='(item, key) in items')
				.py-4.memberInfoCardTitle {{ item.title }}
				div {{ item.email }}
				div {{ item.phone }}
				div {{ item.agentNickName }}
	v-col.px-0.py-1(
		cols='12',
		v-if='this.deposit || this.win || this.avgBet || this.revenue || this.netWin || this.maxNetWin || this.promo || this.regDate || this.dpstTimeLast || this.gmTimeLast || this.regDays || this.depositDates || this.gameDates || this.promoBehavior'
	)
		.px-2.memberCard(v-for='(item, key) in items')
			.memberCardTitle.pt-3 {{ item.title }}
			.text-center.memberCardContent.ml-12.mr-7(:class='{ valuetoGreen: valueToGreen, valuetoRed: valueToRed, textToSmall: textToSmall }') {{ item.content }}
			.memberCardRemark.pb-3.text-right(:class='{ markertoGreen: markToGreen, markertoRed: markToRed }') {{ item.marker }}
	v-col(v-if='this.donutChartData && this.donutSeries.length !== 0')
		v-chart(:option='donutChartOptions', ref='donutChartOptions', autoresize)
	v-col(v-if='this.timeBarChartData')
		v-chart(:option='timeBarChartData', ref='timeBarChartData', autoresize)
	v-col(v-if='this.barChartData')
		v-chart(:option='barChartData', ref='barChartData', autoresize)
	v-col(v-if='this.heatMapChartData && this.heatMapSeries.length !== 0')
		v-chart(:option='heatMapOptions', ref='heatMapOptions', autoresize)
</template>

<script>
import ECharts from 'vue-echarts';
import { bar, heatMap, timeIntervalBar, pie } from '@/assets/echarts/ocmsCommon/customer/single';
import { toThousandslsFilter, roundFilter, roundOffFilter } from '@/util/format';
import DataPicker from '@/components/DataPicker/DataPicker';

export default {
	name: 'Chart',
	components: {
		'v-chart': ECharts,
		DataPicker,
	},
	props: [
		'information',
		'deposit',
		'win',
		'avgBet',
		'revenue',
		'netWin',
		'maxNetWin',
		'promo',
		'regDays',
		'regDate',
		'dpstTimeLast',
		'gmTimeLast',
		'depositDates',
		'gameDates',
		'promoBehavior',
		'timeBarChartData',
		'barChartData',
		'donutChartData',
		'heatMapChartData',
	],
	data() {
		return {
			items: [],
			markerText: [],
			markerNum: [],
			valueToGreen: false,
			valueToRed: false,
			markToGreen: false,
			markToRed: false,
			textToSmall: false,
			color: '',
			graphicText: '',
			legendData: [],
			tooltipData: [],
			seriesData: [],
			barSeries: [],
			timeBarSeries: [],
			barTitleText: '',
			xAxis: [],
			yAxis: [],
			max: 0,
			timeBarChartOptions: {},
			barChartOptions: {},
			donutTitleText: '',
			donutSeries: [],
			donutChartOptions: {},
			heatMapText: '',
			heatMapOptions: {},
			heatMapSeries: [],
		};
	},
	created() {},
	methods: {
		setTimeBarChart() {
			this.timeBarChartOptions = timeIntervalBar();
			this.timeBarChartOptions.color = this.color;
			this.timeBarChartOptions.title.text = this.barTitleText;
			this.timeBarChartOptions.tooltip.formatter = (params) =>
				`<span style='color:#333';>${params[0].name.toString().split('_', 1)}</span> 
      <span style='color:#6C6C6C';>${this.tooltipData[params[0].name.toString().split('_', 1)]}</span><br/>`;
			this.timeBarChartOptions.yAxis[0].data = this.yAxis;
			this.timeBarChartOptions.series.data = this.seriesData;
			this.timeBarSeries = this.seriesData;
		},
		setDataBarChart() {
			this.barChartOptions = bar();
			this.barChartOptions.color = this.color;
			this.barChartOptions.title.text = this.barTitleText;
			this.barChartOptions.tooltip.formatter = (params) =>
				`<span style='color:#333';>${params[0].name.toString().split('_', 1)}</span> 
      <span style='color:#6C6C6C';>${this.tooltipData[params[0].name.toString().split('_', 1)]}</span><br/>`;
			this.barChartOptions.yAxis[0].data = this.yAxis;
			if (this.yAxis.length > 10) {
				this.barChartOptions.grid.right = '15%';
				this.barChartOptions.dataZoom = [
					{ show: true, yAxisIndex: 0, filterMode: 'empty', textStyle: { color: 'none' }, start: 80, end: 100 },
				];
				if (this.yAxis.length > 35) this.barChartOptions.dataZoom[0].start = 95;
			}
			this.barChartOptions.series.data = this.seriesData;
			this.barSeries = this.seriesData;
		},
		setDataDonutChart() {
			this.donutChartOptions = pie();
			this.donutChartOptions.color = this.color;
			this.donutChartOptions.title.text = this.donutTitleText;
			this.donutChartOptions.legend.data = this.legendData;
			this.donutChartOptions.graphic.style.text = this.graphicText;
			this.donutChartOptions.series[0].data = this.seriesData;
			this.donutSeries = this.seriesData;
		},
		setDataHeatMapChart() {
			this.heatMapOptions = heatMap();
			this.heatMapOptions.visualMap.color = this.color;
			this.heatMapOptions.title.text = this.heatMapText;
			this.heatMapOptions.tooltip.formatter = (params) =>
				`${this.$t('charts.day')}: ${this.yAxis[params.data[1]]}<br/>${this.$t('charts.hour')}: ${this.xAxis[params.data[0]]}<br/>${
					params.marker
				}${this.$t('charts.count')}: ${toThousandslsFilter(params.data[2])}`;
			this.heatMapOptions.yAxis.data = this.yAxis;
			this.heatMapOptions.xAxis.data = this.xAxis;
			this.heatMapOptions.visualMap.max = this.max;
			this.heatMapOptions.series[0].data = this.seriesData;
			this.heatMapSeries = this.seriesData;
		},
	},
	watch: {
		information: function (val) {
			this.items = val;
		},
		deposit: function (val) {
			this.items = val;
			if (val.length !== 0) this.items[0].content = roundOffFilter(this.items[0].content);
		},
		win: function (val) {
			this.items = val;
			if (val.length !== 0) this.items[0].content = roundOffFilter(this.items[0].content);
		},
		avgBet: function (val) {
			this.items = val;
			if (val.length !== 0) this.items[0].content = roundFilter(this.items[0].content);
		},
		revenue: function (val) {
			this.items = val;
			if (val.length !== 0) {
				if (Number(this.items[0].content) > 0) {
					this.valueToGreen = true;
					this.valueToRed = false;
				}
				if (Number(this.items[0].content) < 0) {
					this.valueToGreen = false;
					this.valueToRed = true;
				}
				if (Number(this.items[0].content) === 0) {
					this.valueToGreen = false;
					this.valueToRed = false;
				}
				this.items[0].content = roundOffFilter(this.items[0].content);
			}
		},
		netWin: function (val) {
			this.items = val;
			if (val.length !== 0) {
				if (Number(this.items[0].content) > 0) {
					this.valueToGreen = true;
					this.valueToRed = false;
				}
				if (Number(this.items[0].content) < 0) {
					this.valueToGreen = false;
					this.valueToRed = true;
				}
				if (Number(this.items[0].content) === 0) {
					this.valueToGreen = false;
					this.valueToRed = false;
				}
				this.items[0].content = roundOffFilter(this.items[0].content);
				this.markerText = this.items[0].marker.split(':');
				this.markerNum = this.markerText[1].split('%');
				if (Number(this.markerNum[0]) > 0) {
					this.markToGreen = true;
					this.markToRed = false;
				}
				if (Number(this.items[0].content) < 0) {
					this.markToGreen = false;
					this.markToRed = true;
				}
				if (Number(this.markerNum[0]) === 0) {
					this.markToGreen = false;
					this.markToRed = false;
				}
			}
		},
		maxNetWin: function (val) {
			this.items = val;
			if (val.length !== 0) {
				if (Number(this.items[0].content) > 0) {
					this.valueToGreen = true;
					this.valueToRed = false;
				}
				if (Number(this.items[0].content) < 0) {
					this.valueToGreen = false;
					this.valueToRed = true;
				}
				if (Number(this.items[0].content) === 0) {
					this.valueToGreen = false;
					this.valueToRed = false;
				}
				this.items[0].content = roundFilter(this.items[0].content);
				this.markerText = this.items[0].marker.split(':');
				this.markerNum = this.markerText[1].split('th');
			}
		},
		promo: function (val) {
			this.items = val;
			if (val.length !== 0) this.items[0].content = roundOffFilter(this.items[0].content);
		},
		regDays: function (val) {
			this.items = val;
			if (val.length !== 0) this.items[0].content = this.items[0].content;
		},
		regDate: function (val) {
			this.items = val;
		},
		dpstTimeLast: function (val) {
			this.items = val;
		},
		gmTimeLast: function (val) {
			this.items = val;
		},
		depositDates: function (val) {
			this.items = val;
		},
		gameDates: function (val) {
			this.items = val;
		},
		promoBehavior: function (val) {
			this.items = val;
			this.textToSmall = true;
		},
		// timeBarChartData: function(val) {
		// 	this.timeBarSeries = [];
		// 	if (Object.keys(val).length !== 0) {
		// 		this.color = val.color;
		// 		this.barTitleText = val.title;
		// 		this.tooltipData = val.tooltip;
		// 		this.yAxis = val.yAxis;
		// 		this.seriesData = val.series;
		// 		this.setTimeBarChart();
		// 	}
		// },
		// barChartData: function(val) {
		// 	this.barSeries = [];
		// 	if (Object.keys(val).length !== 0) {
		// 		this.color = val.color;
		// 		this.barTitleText = val.title;
		// 		this.tooltipData = val.tooltip;
		// 		this.yAxis = val.yAxis;
		// 		this.seriesData = val.series;
		// 		this.setDataBarChart();
		// 	}
		// },
		donutChartData: function (val) {
			this.donutSeries = [];
			if (Object.keys(val).length !== 0) {
				this.color = val.color;
				this.donutTitleText = val.title;
				this.graphicText = val.graphic;
				this.legendData = val.legend;
				this.seriesData = val.series;
				this.setDataDonutChart();
			}
		},
		heatMapChartData: function (val) {
			this.heatMapSeries = [];
			if (Object.keys(val).length !== 0) {
				this.color = val.color;
				this.heatMapText = val.title;
				this.xAxis = val.xAxis;
				this.yAxis = val.yAxis;
				this.max = val.max;
				this.seriesData = val.series;
				this.setDataHeatMapChart();
			}
		},
	},
};
</script>
