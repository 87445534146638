<template lang="pug">
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab(:ripple='false') {{ $t("charts.everyDay") }}
		v-tabs-items(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							//- DatePicker(@emitupdateTodayDate='updateTodayDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', :setArea='area', :setDomain='value', @emitDomainValue='updateDomainValue')
			v-col.py-0(cols='12', md='6', lg='12')
				v-row.input-wrap.mx-0
					v-col.pa-0.labal-bgc.text-center(cols='3')
						span.caption {{ $t("common.member") }}
					v-col.pa-0(cols='9')
						ValidationProvider(rules='required', v-slot='{ errors }')
							v-text-field.memberInput(
								v-model='targetMember',
								autocomplete='off',
								required,
								:error-messages='errors',
								solo-inverted='',
								:placeholder='enterMemberAccount',
								clearable
							)
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.pt-12.justify-center.px-0.warnText(cols='12', v-if='showWarn')
						v-img(max-width='40px', :src='warn')
						span {{ $t("member.NoResult") }}
				v-row
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:information='informationArr')
					v-col.memberKanban(cols='9')
						v-row
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:deposit='depositArr')
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:win='winArr')
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:avgBet='avgBetArr')
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:revenue='valueArr')
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:netWin='netWinArr')
							v-col.memberKanban(cols='4')
								MemberKanban.mx-1(:maxNetWin='maxNetWinArr')
				v-row
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:regDate='regDateArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:dpstTimeLast='dpstTimeLastArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:gmTimeLast='gmTimeLastArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:promo='promoArr')
				v-row
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:regDays='regDaysArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:depositDates='depositDatesArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:gameDates='gameDatesArr')
					v-col.memberKanban(cols='3')
						MemberKanban.mx-1(:promoBehavior='promoBehaviorArr')
				v-row
					v-col.memberChart.nopadding(cols='3')
						MemberKanban.mx-1(:timeBarChartData='barChartDataObj1')
					v-col.memberChart.nopadding(cols='3')
						MemberKanban.mx-1(:timeBarChartData='barChartDataObj2')
					v-col.memberChart.nopadding(cols='3')
						MemberKanban.mx-1(:timeBarChartData='barChartDataObj3')
					v-col.memberChart.nopadding(cols='3')
						MemberKanban.mx-1(:timeBarChartData='barChartDataObj4')
				v-row(v-if='dataSwitch')
					v-col.dataSelectBox
						SwitchData(:dataStatus='switchValue', @emitupdateData='updateData')
				v-row
					v-col.memberChart(cols='4')
						v-chart(:option='gbrandBar', ref='gbrandBar', autoresize)
					v-col.memberChart(cols='4')
						v-chart(:option='kindBar', ref='kindBar', autoresize)
					v-col.memberChart(cols='4')
						v-chart(:option='themeBar', ref='themeBar', autoresize)
				v-row
					v-col.memberChart(cols='4')
						MemberKanban.mx-1(:donutChartData='pieChartDataObj1')
					v-col.memberChart(cols='8')
						MemberKanban.mx-1(:heatMapChartData='heatMapDataObj1')
				v-row
					v-col.memberChart(cols='4')
						MemberKanban.mx-1(:donutChartData='pieChartDataObj2')
					v-col.memberChart(cols='8')
						MemberKanban.mx-1(:heatMapChartData='heatMapDataObj2')
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
.nopadding {
	padding: 0 !important;
	/* margin: 0 !important; */
}
</style>
<script>
import _ from 'lodash';
import ECharts from 'vue-echarts';
import statusCode from '@/assets/constant/statusCode';
import { bar, timeIntervalBar } from '@/assets/echarts/ocmsCommon/customer/single';
import { mapActions, mapGetters } from 'vuex';
import { commaFormatter, rdPercent, roundDecimal, trans, toThousandslsFilter, roundOffFilter } from '@/util/format';
import statusMsg from '@/util/http';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { getSingleMember } from '@/api/Customer';
import { exportXlsx } from '@/util/xlsx';
import DatePicker from '@/components/DatePicker/DatePicker_ocms';
import ExportBtn from '@/components/ExportBtn';
import SwitchData from '@/components/DataPicker/SwitchData';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import MemberKanban from '@/components/MemberKanban';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		ValidationObserver,
		ValidationProvider,
		DatePicker,
		ExportBtn,
		MemberKanban,
		SwitchData,
		SingleSelectCurrency
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			cardType: '',
			dialog: false,
			currencyStatus: true,
			show: false,
			helperShow: false,
			loading: false,
			areaSwitch: true,
			domainSwitch: true,
			dataSwitch: false,
			enterMemberAccount: 'Enter Member Account',
			targetMember: '',
			getExportResult: {},
			basic: {
				items: [],
			},
			dates: [],
			searchDates: [],
			turnOff: false,
			switchValue: this.$t('charts.turnoverDesc'),
			value: [],
			area: [],
			areaList: [],
			domain: [],
			domainMap: {},
			tab: null,
			menuProps: {
				offsetY: true,
			},
			imgSrc: require('@/assets/image/research.png'),
			warn: require('@/assets/image/warn.png'),
			showWarn: false,
			rankBrand: {},
			rankGameKind: {},
			rankGameTheme: {},
			informationArr: [],
			depositArr: [],
			winArr: [],
			avgBetArr: [],
			valueArr: [],
			maxNetWinArr: [],
			netWinArr: [],
			promoArr: [],
			bdName: new Map(),
			bdtool: new Map(),
			bdValue: new Map(),
			gkName: new Map(),
			gktool: new Map(),
			gkValue: new Map(),
			gtName: new Map(),
			gttool: new Map(),
			gtValue: new Map(),
			regDateArr: [],
			gmTimeLastArr: [],
			regDaysArr: [],
			dpstTimeLastArr: [],
			depositDatesArr: [],
			gameDatesArr: [],
			promoBehaviorArr: [],
			themeBar: {},
			gbrandBar: {},
			kindBar: {},
			barChartDataObj1: {},
			barChartDataObj2: {},
			barChartDataObj3: {},
			barChartDataObj4: {},
			barBrandDataObj: {},
			barGameKindDataObj: {},
			barGameThemeDataObj: {},
			pieChartDataObj1: {},
			pieChartDataObj2: {},
			heatMapDataObj1: {},
			heatMapDataObj2: {},
		};
	},
	mounted() {
		this.initData();
		this.onResize();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		smoothDown() {
			$('html, body').animate(
				{
					scrollTop: $('.searchBtn').offset().top,
				},
				500
			);
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.date).subtract(1, 'day').format('YYYY-MM-DD'),
					domain: [this.value],
					memberID: this.targetMember,
					// startDate: '2020-08-20',
					// domain: ['jw'],
					// memberID: 'GOPAL786'
				};
				this.searchDates = [reqData.startDate];
				const res = await getSingleMember(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = JSON.parse(JSON.stringify(res.result.res));
					localStorage.setItem('currency', this.currency);
					const aggTrans = result.ctx_agg_trans;
					const popularTimes = result.ctx_popular_times;
					const prevAll = result.ctx_rpt_recap.basic_all;
					this.prankAll = result.ctx_rpt_recap.prank_all;
					const prevNDays = result.ctx_rpt_recap.termly_all;
					// const profile = result.ctx_rpt_recap.basic_all;
					this.gaming = result.ctx_rpt_recap.gm_sub;
					if (Object.is(this.gaming.length, 0)) {
						this.dataSwitch = false;
					} else {
						this.dataSwitch = true;
					}
					this.mamberData = [
						{
							name: result.ctx_rpt_recap.dim_member_user_id,
							information: {
								// email: profile.email,
								// agentNickName: profile.agent_nick_name,
								// phone: profile.phone,
							},
							deposit: { content: prevAll.dpst_amt, rank: this.prankAll.dpst_amt_sum_pr },
							bet: { content: prevAll.apv_bet, rank: this.prankAll.gm_apv_bet_amt_sum_pr },
							avgBet: { content: prevAll.avg_bet_per_round },
							revenue: { content: prevAll.revenue_amt },
							netWin: { content: prevAll.house_net_win_sum, rate: prevAll.house_edge },
							maxNetWin: { content: prevAll.play_game_win_max, rate: this.prankAll.gm_play_game_win_amt_max_pr },
							promo: { content: prevAll.bonus_amt },
							regDays: { content: prevAll.reg_days_ago },
							depositDates: { content: prevAll.dpst_term },
							gameDates: { content: prevAll.gm_term },
							regDate: { content: prevAll.reg_time_min },
							dpstTimeLast: { content: prevAll.dpst_time_max, range: prevAll.dpst_days_ago },
							gmTimeLast: { content: prevAll.gm_time_max, range: prevAll.gm_days_ago },
							promoBehavior: {
								content: prevAll.join_count,
								accessTimes: prevAll.done_count,
								rate: prevAll.promo_done_rate,
							},
						},
					];
					this.cardDisplay();
					this.aggPie(aggTrans);
					this.gaming.sort((a, b) => (a.dim_device_id > b.dim_device_id ? 1 : -1));
					this.gamePie(await trans(this.gaming, 'dim_device_id', 'gm_count'));
					this.depositCharts(prevNDays);
					this.gaming.sort((a, b) => (a.dim_brand_id > b.dim_brand_id ? 1 : -1));
					this.brandBar(await trans(this.gaming, 'dim_brand_id', 'apv_bet'), await trans(this.gaming, 'dim_brand_id', 'house_net_win'));
					this.gaming.sort((a, b) => (a.game_kind_name > b.game_kind_name ? 1 : -1));
					this.gameKindBar(await trans(this.gaming, 'game_kind_name', 'apv_bet'), await trans(this.gaming, 'game_kind_name', 'house_net_win'));
					this.gaming.sort((a, b) => (a.game_name > b.game_name ? 1 : -1));
					this.gameThemeBar(await trans(this.gaming, 'game_name','apv_bet'), await trans(this.gaming, 'game_name', 'house_net_win'));
					this.popularCharts(popularTimes);
					this.getExportResult = result;
				} else {
					let errMsg = statusMsg(res.status);
					this.errorDialogStatus(errMsg);
					this.allClean();
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		cardDisplay() {
			const resultMember = this.mamberData;
			const nameArr = resultMember.map((obj) => obj.name);
			if (nameArr.indexOf(this.targetMember) !== -1 && resultMember.length !== 0) {
				this.showWarn = false;
				this.smoothDown();
				this.tab = 0;
				this.informationArr = resultMember.map((obj) => obj.information);
				// this.informationArr[0].email = `${this.$t('member.email')}: ${this.informationArr[0]['email']}`;
				// this.informationArr[0].agentNickName = `${this.$t('member.agentNickName')}: ${this.informationArr[0]['agentNickName']}`;
				// this.informationArr[0].phone = `${this.$t('member.phone')}: ${this.informationArr[0]['phone']}`;
				this.informationArr[0].title = this.$t('member.basicInfo');

				this.depositArr = resultMember.map((obj) => obj.deposit);
				delete Object.assign(this.depositArr[0], { ['marker']: roundDecimal(this.depositArr[0]['rank'] * 100) })['rank'];
				this.depositArr[0].marker = `PR: ${this.depositArr[0]['marker']} th`;
				this.depositArr[0].title = this.$t('member.totalDpst');

				this.winArr = resultMember.map((obj) => obj.bet);
				delete Object.assign(this.winArr[0], { ['marker']: roundDecimal(this.winArr[0]['rank'] * 100) })['rank'];
				this.winArr[0].marker = `PR: ${this.winArr[0]['marker']} th`;
				this.winArr[0].title = this.$t('member.totalBet');
				this.avgBetArr = resultMember.map((obj) => obj.avgBet);
				this.avgBetArr[0].title = this.$t('member.avgBet');

				this.valueArr = resultMember.map((obj) => obj.revenue);
				this.valueArr[0].title = this.$t('member.totalRevenue');

				this.netWinArr = resultMember.map((obj) => obj.netWin);
				delete Object.assign(this.netWinArr[0], { ['marker']: this.netWinArr[0]['rate'] })['rate'];
				this.netWinArr[0].marker = `House Edge: ${roundDecimal(this.netWinArr[0]['marker'] * 100)}%`;
				this.netWinArr[0].title = this.$t('member.totalNetWin');

				this.maxNetWinArr = resultMember.map((obj) => obj.maxNetWin);
				delete Object.assign(this.maxNetWinArr[0], { ['marker']: roundDecimal(this.maxNetWinArr[0]['rate'] * 100) })['rate'];
				this.maxNetWinArr[0].marker = `PR: ${this.maxNetWinArr[0]['marker']} th`;
				this.maxNetWinArr[0].title = this.$t('member.maxNetWin');

				this.promoArr = resultMember.map((obj) => obj.promo);
				this.promoArr[0].title = this.$t('member.totalPromoCredit');

				this.regDateArr = resultMember.map((obj) => obj.regDate);
				this.regDateArr[0].title = this.$t('member.regDate');
				this.dpstTimeLastArr = resultMember.map((obj) => obj.dpstTimeLast);
				delete Object.assign(this.dpstTimeLastArr[0], { ['marker']: this.dpstTimeLastArr[0]['range'] })['range'];
				// if(Number(this.dpstTimeLastArr[0]['marker']) === 0) {
				// 	this.dpstTimeLastArr[0].marker = this.$t('member.yesterday');
				// } else {
				// 	this.dpstTimeLastArr[0].marker = `${this.dpstTimeLastArr[0]['marker']} ${this.$t('member.daysBefore')}`;
				// }
				this.dpstTimeLastArr[0].marker = `${this.dpstTimeLastArr[0]['marker']} ${this.$t('member.daysBefore')}`;
				this.dpstTimeLastArr[0].title = this.$t('member.dpstTimeLast');
				this.gmTimeLastArr = resultMember.map((obj) => obj.gmTimeLast);
				delete Object.assign(this.gmTimeLastArr[0], { ['marker']: this.gmTimeLastArr[0]['range'] })['range'];
				this.gmTimeLastArr[0].marker = `${this.gmTimeLastArr[0]['marker']} ${this.$t('member.daysBefore')}`;
				this.gmTimeLastArr[0].title = this.$t('member.gmTimeLast');

				this.regDaysArr = resultMember.map((obj) => obj.regDays);
				this.regDaysArr[0].content = `${roundOffFilter(this.regDaysArr[0]['content'])} ${this.$t('member.day')}`;
				this.regDaysArr[0].title = this.$t('member.regDateToNow');

				this.depositDatesArr = resultMember.map((obj) => obj.depositDates);
				this.depositDatesArr[0].content = `${roundOffFilter(this.depositDatesArr[0]['content'])} ${this.$t('member.day')}`;
				this.depositDatesArr[0].title = this.$t('member.depositDates');

				this.gameDatesArr = resultMember.map((obj) => obj.gameDates);
				this.gameDatesArr[0].content = `${roundOffFilter(this.gameDatesArr[0]['content'])} ${this.$t('member.day')}`;
				this.gameDatesArr[0].title = this.$t('member.gamingDates');

				this.promoBehaviorArr = resultMember.map((obj) => obj.promoBehavior);
				this.promoBehaviorArr[0].content = `${this.$t('member.participate')} ${this.promoBehaviorArr[0]['content']} ${this.$t(
					'member.times'
				)} (${this.$t('member.accomplish')} ${this.promoBehaviorArr[0]['accessTimes']} ${this.$t('member.times')})`;
				delete Object.assign(this.promoBehaviorArr[0], { ['marker']: this.promoBehaviorArr[0]['rate'] })['rate'];
				this.promoBehaviorArr[0].marker = `${this.$t('member.achieveRate')}: ${roundDecimal(
					this.promoBehaviorArr[0]['marker'] * 100
				)}%`;
				this.promoBehaviorArr[0].title = this.$t('member.promoBehavior');
			} else {
				this.allClean();
				this.showWarn = true;
			}
		},
		aggPie(res) {
			const legendData = [],
				seriesData = [];
			if (res.length) {
				let countSum = [];
				for (let i of res) {
					legendData.push(i.dim_txn_type);
					countSum.push(i.dpst_count);
					seriesData.push({
						value: i.dpst_count,
						name: i.dim_txn_type,
					});
				}
				let graphicText = countSum.reduce((a, b) => a + b);
				graphicText = this.$t('member.totalDpstCount') + '\n' + toThousandslsFilter(graphicText);
				this.pieChartDataObj1 = {
					color: JSON.parse(JSON.stringify(COLORS.green_sea)).reverse(),
					title: this.$t('member.eachTypeDpstCount'),
					graphic: graphicText,
					legend: legendData,
					series: seriesData,
				};
			}
		},
		gamePie(res) {
			const legendData = [],
				seriesData = [];
			if (res.length) {
				let countSum = [];
				for (let i of res) {
					legendData.push(i.dim_device_id);
					countSum.push(i.gm_count);
					seriesData.push({
						value: i.gm_count,
						name: i.dim_device_id,
					});
				}
				let graphicText = countSum.reduce((a, b) => a + b);
				graphicText = this.$t('member.totalGameCount') + '\n' + toThousandslsFilter(graphicText);
				this.pieChartDataObj2 = {
					color: JSON.parse(JSON.stringify(COLORS.peter_river)).reverse(),
					title: this.$t('member.eachDeviceGameCount'),
					graphic: graphicText,
					legend: legendData,
					series: seriesData,
				};
			}
		},
		depositCharts(res) {
			const yAxisData = [],
				yAxis1 = [],
				yAxis2 = [],
				yAxis3 = [],
				yAxis4 = [],
				betAmtData = [],
				dpstAmtData = [],
				dpstStickyData = [],
				gmStickyData = [];
			const tooltipObj1 = {},
				tooltipObj2 = {},
				tooltipObj3 = {},
				tooltipObj4 = {};
			if (res) {
				for (let i of res) {
					yAxisData.push(i.dim_prev_days);
					betAmtData.push(i.avg_bet_per_day);
					dpstAmtData.push(i.avg_dpst_amt_per_day);
					dpstStickyData.push(roundDecimal(i.dpst_stickiness * 100));
					gmStickyData.push(roundDecimal(i.gm_stickiness * 100));
				}
				for (let i in yAxisData) {
					yAxis1.push(`${yAxisData[i]}` + `_${roundOffFilter(dpstAmtData[i])}`);
					yAxis2.push(`${yAxisData[i]}` + `_${dpstStickyData[i]}%`);
					yAxis3.push(`${yAxisData[i]}` + `_${roundOffFilter(betAmtData[i])}`);
					yAxis4.push(`${yAxisData[i]}` + `_${gmStickyData[i]}%`);
					tooltipObj1[yAxisData[i]] = `${roundOffFilter(dpstAmtData[i])}`;
					tooltipObj2[yAxisData[i]] = `${dpstStickyData[i]}%`;
					tooltipObj3[yAxisData[i]] = `${roundOffFilter(betAmtData[i])}`;
					tooltipObj4[yAxisData[i]] = `${gmStickyData[i]}%`;
				}
				this.barChartDataObj1 = timeIntervalBar({
					color: COLORS.amethyst[3],
					title: this.$t('member.avgDpstPerDay'),
					tooltip: tooltipObj1,
					yAxis: yAxis1.reverse(),
					series: dpstAmtData.reverse(),
				});
				this.barChartDataObj2 = timeIntervalBar({
					color: COLORS.amethyst[4],
					title: this.$t('member.dpstStickiness'),
					tooltip: tooltipObj2,
					yAxis: yAxis2.reverse(),
					series: dpstStickyData.reverse(),
				});
				this.barChartDataObj3 = timeIntervalBar({
					color: COLORS.peter_river[7],
					title: this.$t('member.avgBetPerDay'),
					tooltip: tooltipObj3,
					yAxis: yAxis3.reverse(),
					series: betAmtData.reverse(),
				});
				this.barChartDataObj4 = timeIntervalBar({
					color: COLORS.peter_river[8],
					title: this.$t('member.gamingStickiness'),
					tooltip: tooltipObj4,
					yAxis: yAxis4.reverse(),
					series: gmStickyData.reverse(),
				});
			}
		},
		brandBar(res, res2) {
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			const tooltipObj1 = {},
				tooltipObj2 = {};
			if (res.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					tooltipObj1[res[i].dim_brand_id] = `Rank : ${res.length - i}`;
					data2.push(res2[i].house_net_win);
					tooltipObj2[res2[i].dim_brand_id] = `Rank : ${res.length - i}`;
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(res[i].dim_brand_id + `_${roundOffFilter(res[i].apv_bet)}` + `_(${rdPercent(res[i].apv_bet / totalBet)})`);
					yAxisData2.push(
						res2[i].dim_brand_id + `_${roundOffFilter(res2[i].house_net_win)}` + `_(${rdPercent(res2[i].house_net_win / totalNetWin)})`
					);
				}
				this.bdName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.bdName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.bdtool.set(this.$t('charts.turnoverDesc'), tooltipObj1);
				this.bdtool.set(this.$t('charts.netWinDesc'), tooltipObj2);
				this.bdValue.set(this.$t('charts.turnoverDesc'), data1);
				this.bdValue.set(this.$t('charts.netWinDesc'), data2);
				const tmp = {
					color: COLORS.silver[6],
					title: this.$t('member.barndSummary'),
					tooltip: this.bdtool.get(this.switchValue),
					yAxis: this.bdName.get(this.switchValue),
					series: this.bdValue.get(this.switchValue),
				};
				this.gbrandBar = bar(tmp);
			}
		},
		gameKindBar(res, res2) {
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			const tooltipObj1 = {},
				tooltipObj2 = {};
			if (res.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					tooltipObj1[res[i].game_kind_name] = `Rank : ${res.length - i}`;
					data2.push(res2[i].house_net_win);
					tooltipObj2[res2[i].game_kind_name] = `Rank : ${res.length - i}`;
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(res[i].game_kind_name + `_${roundOffFilter(res[i].apv_bet)}` + `_(${rdPercent(res[i].apv_bet / totalBet)})`);
					yAxisData2.push(
						res2[i].game_kind_name + `_${roundOffFilter(res2[i].house_net_win)}` + `_(${rdPercent(res2[i].house_net_win / totalNetWin)})`
					);
				}
				this.gkName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.gkName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.gktool.set(this.$t('charts.turnoverDesc'), tooltipObj1);
				this.gktool.set(this.$t('charts.netWinDesc'), tooltipObj2);
				this.gkValue.set(this.$t('charts.turnoverDesc'), data1);
				this.gkValue.set(this.$t('charts.netWinDesc'), data2);
				const tmp = {
					color: COLORS.silver[7],
					title: this.$t('member.gameKindSummary'),
					tooltip: this.gktool.get(this.switchValue),
					yAxis: this.gkName.get(this.switchValue),
					series: this.gkValue.get(this.switchValue),
				};
				this.kindBar = bar(tmp);
			}
		},
		gameThemeBar(res, res2) {
			const yAxisData1 = [],
				data1 = [],
				yAxisData2 = [],
				data2 = [];
			const tooltipObj1 = {},
				tooltipObj2 = {};
			if (res.length) {
				res.sort((a, b) => (a.apv_bet > b.apv_bet ? 1 : -1));
				res2.sort((a, b) => (a.house_net_win > b.house_net_win ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					data1.push(res[i].apv_bet);
					tooltipObj1[res[i].game_name] = `Rank : ${res.length - i}`;
					data2.push(res2[i].house_net_win);
					tooltipObj2[res2[i].game_name] = `Rank : ${res.length - i}`;
				}
				const totalBet = data1.reduce((total, val) => {
					return total + val;
				}, 0);
				const totalNetWin = data2.reduce((total, val) => {
					return total + val;
				}, 0);
				for (let i = 0; i < res.length; i += 1) {
					yAxisData1.push(res[i].game_name + `_${roundOffFilter(res[i].apv_bet)}` + `_(${rdPercent(res[i].apv_bet / totalBet)})`);
					yAxisData2.push(
						res2[i].game_name + `_${roundOffFilter(res2[i].house_net_win)}` + `_(${rdPercent(res2[i].house_net_win / totalNetWin)})`
					);
				}
				this.gtName.set(this.$t('charts.turnoverDesc'), yAxisData1);
				this.gtName.set(this.$t('charts.netWinDesc'), yAxisData2);
				this.gttool.set(this.$t('charts.turnoverDesc'), tooltipObj1);
				this.gttool.set(this.$t('charts.netWinDesc'), tooltipObj2);
				this.gtValue.set(this.$t('charts.turnoverDesc'), data1);
				this.gtValue.set(this.$t('charts.netWinDesc'), data2);
				const tmp = {
					color: COLORS.silver[8],
					title: this.$t('member.gameThemeSummary'),
					tooltip: this.gttool.get(this.switchValue),
					yAxis: this.gtName.get(this.switchValue),
					series: this.gtValue.get(this.switchValue),
				};
				this.themeBar = bar(tmp);
			}
		},
		popularCharts(res) {
			let gmData = [],
				dpstData = [],
				hour = [],
				tmp1 = [],
				tmp2 = [];
			const week = [
				this.$t('common.saturday'),
				this.$t('common.friday'),
				this.$t('common.thursday'),
				this.$t('common.wednesday'),
				this.$t('common.tuesday'),
				this.$t('common.monday'),
				this.$t('common.sunday'),
			];
			if (res.length) {
				for (let i of res) {
					const dayIndex = Math.abs(i.dim_day_of_week - 7);
					gmData.push([i.dim_hour, dayIndex, i.gm_count]);
					dpstData.push([i.dim_hour, dayIndex, i.dpst_count]);
					tmp1.push(i.gm_count);
					tmp2.push(i.dpst_count);
				}
				for (let i = 0; i < 24; i += 1) {
					hour.push(i);
				}
				const max1 = tmp1.reduce((a, b) => Math.max(a, b));
				const max2 = tmp2.reduce((a, b) => Math.max(a, b));
				gmData = gmData.map(function (item) {
					return [item[0], item[1], item[2] || '-'];
				});
				dpstData = dpstData.map(function (item) {
					return [item[0], item[1], item[2] || '-'];
				});
				this.heatMapDataObj1 = {
					color: JSON.parse(JSON.stringify(COLORS.green_sea)).reverse(),
					title: this.$t('member.eachTimePeriodDpst'),
					xAxis: hour,
					yAxis: week,
					max: max2,
					series: dpstData,
				};
				this.heatMapDataObj2 = {
					color: JSON.parse(JSON.stringify(COLORS.peter_river)).reverse(),
					title: this.$t('member.eachTimePeriodGameCount'),
					xAxis: hour,
					yAxis: week,
					max: max1,
					series: gmData,
				};
			}
		},
		resultMember(mamberData) {
			let target = this.targetMember;
			return mamberData.filter(function (person) {
				return person.name.toLowerCase().indexOf(target.toLowerCase()) >= 0;
			});
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				let obj = {};
				// object too complex
				obj.dim_member_user_id = [{dim_member_user_id: result.ctx_rpt_recap.dim_member_user_id}];
				obj.basic_all = [result.ctx_rpt_recap.basic_all];
				obj.gm_sub = result.ctx_rpt_recap.gm_sub;
				obj.prank_all = [result.ctx_rpt_recap.prank_all];
				obj.termly_all = result.ctx_rpt_recap.termly_all;
				obj.ctx_agg_trans = result.ctx_agg_trans;
				obj.ctx_popular_times = result.ctx_popular_times;
				await exportXlsx('d', this.searchDates, obj);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		allClean() {
			this.basic.items = [];
			this.informationArr = [];
			this.depositArr = [];
			this.winArr = [];
			this.avgBetArr = [];
			this.valueArr = [];
			this.netWinArr = [];
			this.maxNetWinArr = [];
			this.promoArr = [];
			this.bdName = new Map();
			this.bdtool = new Map();
			this.bdValue = new Map();
			this.gkName = new Map();
			this.gktool = new Map();
			this.gkValue = new Map();
			this.gtName = new Map();
			this.gttool = new Map();
			this.gtValue = new Map();
			this.regDaysArr = [];
			this.regDateArr = [];
			this.dpstTimeLastArr = [];
			this.gmTimeLastArr = [];
			this.depositDatesArr = [];
			this.gameDatesArr = [];
			this.promoBehaviorArr = [];
			this.barChartDataObj1 = {};
			this.barChartDataObj2 = {};
			this.barChartDataObj3 = {};
			this.barChartDataObj4 = {};
			this.barBrandDataObj = {};
			this.barGameKindDataObj = {};
			this.barGameThemeDataObj = {};
			this.pieChartDataObj1 = {};
			this.pieChartDataObj2 = {};
			this.heatMapDataObj1 = {};
			this.heatMapDataObj2 = {};
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domain = val2;
			this.currency = val3;
		},
		selectItemName(data, items, index) {
			return index === 0 && data;
		},
		toInt(data) {
			return _.toInteger(data.replace(/,/g, ''));
		},
		centNumToInteger(number) {
			return commaFormatter(number);
		},
		async updateData(val) {
			this.switchValue = val;
		},
		updateTodayDate(val) {
			this.date = val;
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		// domain: function (value) {
		// 	const arr = JSON.parse(JSON.stringify(value));
		// 	if (arr.length === 0) {
		// 		this.turnOff = true;
		// 	} else {
		// 		this.turnOff = false;
		// 	}
		// },
		// area: function (value) {
		// 	let domainValue = this.getDomain['OCMS'][value];
		// 	let tmp = Object.values(domainValue);
		// 	this.domain = tmp;
		// 	if (this.domain.length <= 1) {
		// 		this.domainSwitch = false;
		// 		this.value = this.domain[0];
		// 	} else {
		// 		this.domainSwitch = true;
		// 	}
		// },
		switchValue: function (value) {
			this.gbrandBar = bar({
				color: COLORS.silver[6],
				title: this.$t('member.barndSummary'),
				tooltip: this.bdtool.get(value),
				yAxis: this.bdName.get(value),
				series: this.bdValue.get(value),
			});
			this.kindBar = bar({
				color: COLORS.silver[7],
				title: this.$t('member.gameKindSummary'),
				tooltip: this.gktool.get(value),
				yAxis: this.gkName.get(value),
				series: this.gkValue.get(value),
			});
			this.themeBar = bar({
				color: COLORS.silver[8],
				title: this.$t('member.gameThemeSummary'),
				tooltip: this.gttool.get(value),
				yAxis: this.gtName.get(value),
				series: this.gtValue.get(value),
			});
		},
		getExportResult: function () {
			if (Object.keys(this.getExportResult).length !== 0) {
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList', 'getExchangeRate', 'getDialogShow', 'getStatusDialog']),
	},
	created() {
		this.areaList = Object.keys(this.getDomain['OCMS']);
		this.areaList.sort();
		if (this.areaList.length <= 1) {
			this.areaSwitch = false;
			this.area = this.areaList[0];
		}
		if (localStorage.getItem('searchMember')) {
			const data = JSON.parse(localStorage.getItem('searchMember'));
			this.currency = localStorage.getItem('currency');
			this.area = data.area;
			this.value = data.domain;
			this.targetMember = data.member;
			this.send();
			localStorage.removeItem('searchMember');
		} else {
			this.allClean();
		}
	},
};
</script>
